import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LookupClient,
  MyAccountAccountsClient,
  PaymentLinkActionHistoryViewModel,
  PaymentLinkActionType,
  PaymentLinkClient,
  PaymentLinkErrorViewModel,
  PaymentLinkViewModel,
  PaymentTypeEnum,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { interval, of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-my-account-userlink",
  templateUrl: "./link-details.component.html",
  styleUrls: ["./link-details.component.scss"],
})
export class LinkDetailsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private refreshSubscription: Subscription = new Subscription();
  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private lookupClient: LookupClient,
    private snackBarService: SnackBarService,
    private paymentLinkClient: PaymentLinkClient,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  isLoading = false;
  isLoadingAccount = false;

  id: number;

  paymentLink: PaymentLinkViewModel;

  selectedInvoices = "";

  isIVR = true;
  isEcommerce = false;

  linkClickedOn: boolean = false;
  linkClickedOnDate: Date;
  paymentEntered: boolean = false;
  paymentCompleted: boolean = false;
  paymentCompletedAmount: string = "";
  linkResent: boolean = false;
  linkResentOn: Date;
  errors: PaymentLinkErrorViewModel[] = [];


  ngOnInit() {
    this.isLoading = true;
    this.id = this.activatedRoute.snapshot.params["id"];
    this.loadDetails(true);
    this.isLoading = false;

    // every 20 seconds, call the loadActionHistory method unless paymentCompleted = true
    this.refreshSubscription = interval(20000)
      .pipe(
        switchMap(() => {
          if (!this.paymentCompleted) {
            console.log("Refreshing action history");
            return of(this.loadActionHistory());
          }
          return of(null);
        })
      )
      .subscribe()
  }

  loadActionHistory()
  {
    this.subscription.add(
      this.paymentLinkClient.getPaymentLinkActions(this.id).subscribe(
        ((result) => {
          // if this has a value, then linkClickedOn is true
          this.linkClickedOn = result.result.clickedOn != null;
          this.linkClickedOnDate = result.result.clickedOn;
          this.paymentEntered = result.result.paymentMethodEntered;
          this.paymentCompleted = result.result.completed;
          this.linkResent = result.result.lastSentOn != null;
          this.linkResentOn = result.result.lastSentOn;
          this.errors = result.result.errors;
          if(this.paymentCompleted)
          {
            this.loadDetails(false);
          }
        })
      )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadDetails(loadActionHistory : boolean = false) {
    this.isLoading = true;
    this.subscription.add(
      this.paymentLinkClient.getPaymentLinkDetails(
        this.id
      ).subscribe(
        (result) => {
          this.paymentLink = result.result;
          if(this.paymentLink.paymentType == PaymentTypeEnum.Ecommerce)
          {
            // this is an ecommerce payment link, we send them to the ecommerce page
            this.isEcommerce = true;
          }
          if (this.paymentLink.paymentType == PaymentTypeEnum.CheckoutLink) {
            this.isIVR = false;
          }
          if (this.paymentLink.invoiceDetails && this.paymentLink.invoiceDetails.length > 0) {
            this.selectedInvoices = this.paymentLink.invoiceDetails.map(invoice => invoice.invoiceNumber).join(", ");
          }
          
          if(loadActionHistory)
          {
            this.loadActionHistory();
          }
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
    this.isLoading = false;
  }

  goBack()
  {
    // if there are no query params, then just go back to the previous page
    if(Object.keys(this.activatedRoute.snapshot.queryParams).length === 0)
    {
        // simulate the user clicking the back button on their browser
        window.history.back();
    }
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.router.navigate(['/payment-link'], { queryParams });
  }

  copyLink() {
    let data = ("");
    if (this.isIVR)
    {
      data = (
        this.paymentLink.uniqueId
      );
    }
    else{
      data = (
        this.paymentLink.paymentLinkUrl
      );
    }
    
    navigator.clipboard.writeText(data).then(
      success => this.snackBarService.ShowSuccess("Successfully copied")
    ).catch(
      e => this.snackBarService.ShowError("There was an issue while copying")
    );

  }

  resend() {
    this.subscription.add(
      this.paymentLinkClient.resendLink(
        this.id
      ).subscribe(
        (result) => {
          window.location.reload();
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

}
