import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-json-viewer',
  template: `
    <ul *ngIf="parsedJson">
      <li *ngFor="let key of objectKeys(parsedJson)">
        <div *ngIf="isTopLevel(parsedJson[key]); else nonCollapsible">
          <span (click)="toggleCollapse(key)" class="toggle-button">
            [{{ isCollapsed(key) ? '+' : '-' }}]
          </span>
          <strong>{{ key }}:</strong>
          <div *ngIf="!isCollapsed(key)">
            <app-json-viewer [json]="parsedJson[key]"></app-json-viewer>
          </div>
        </div>
        <ng-template #nonCollapsible>
          <strong>{{ key }}:</strong>
          <div *ngIf="isObject(parsedJson[key]) || isArray(parsedJson[key]); else plainValue">
            <app-json-viewer [json]="parsedJson[key]"></app-json-viewer>
          </div>
          <ng-template #plainValue>{{ parsedJson[key] }}</ng-template>
        </ng-template>
      </li>
    </ul>
  `,
  styles: [`
    ul {
      list-style-type: none;
      padding-left: 20px;
    }
    li {
      margin-bottom: 5px;
    }
    .toggle-button {
      cursor: pointer;
      margin-right: 5px;
      color: #007BFF;
      font-weight: bold;
    }
    .toggle-button:hover {
      text-decoration: underline;
    }
  `],
})
export class JsonViewerComponent implements OnChanges {
  @Input() json: any; // Input JSON (could be string or object)
  parsedJson: any; // Parsed JSON object
  objectKeys = Object.keys;
  collapsedKeys: Record<string, boolean> = {}; // Track collapsed state for top-level keys only

  // Watch for input changes and parse the JSON if needed
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['json']) {
      this.parsedJson = this.parseJson(this.json);
      this.initializeCollapsedKeys();
    }
  }

  // Initialize only top-level keys as collapsed
  initializeCollapsedKeys(): void {
    this.collapsedKeys = {};
    if (this.parsedJson) {
      for (const key of this.objectKeys(this.parsedJson)) {
        this.collapsedKeys[key] = true; // Start all top-level keys collapsed
      }
    }
  }

  // Toggle collapsed state for a top-level key
  toggleCollapse(key: string): void {
    this.collapsedKeys[key] = !this.collapsedKeys[key];
  }

  // Check if a top-level key is collapsed
  isCollapsed(key: string): boolean {
    return this.collapsedKeys[key];
  }

  // Check if a value is an object or array to determine collapsibility
  isTopLevel(value: any): boolean {
    return this.isObject(value) || this.isArray(value);
  }

  // Checks if a value is an object
  isObject(value: any): boolean {
    return value && typeof value === 'object' && !Array.isArray(value);
  }

  // Checks if a value is an array
  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  // Parses the JSON string or returns the object if already parsed
  parseJson(value: any): any {
    try {
      if (typeof value === 'string') {
        return JSON.parse(value.trim());
      }
      return value; // Return the object as-is
    } catch (error) {
      console.error('Invalid JSON string:', error);
      return null; // Return null if parsing fails
    }
  }
}
