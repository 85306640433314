import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import {
  BroadcastService,
  MsalAngularConfiguration,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
} from "@azure/msal-angular";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { Configuration } from "msal";
import {
  BusinessUnitClient,
  FacilityClient,
  HolidayClient,
  LookupClient,
  RequestsClient,
  UsersClient,
  GroupsClient,
  MyAccountUsersClient,
  MyAccountFusionClient,
  MyAccountNotificationClient,
  MyAccountAccountsClient,
} from "./apis/customer-care-api.generated";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SecurePipe } from "./features/authentication/auth.secure.pipe";
import { CloseRequestNotesDialogComponent } from "./features/requests/request-details-page/close-request-notes-dialog/close-request-notes-dialog.component";
import { UserEditPageComponent } from "./features/users/user-edit-page/user-edit-page.component";
import { AssigneeDialogComponent } from "./features/requests/request-list-page/assignee-dialog/assignee-dialog.component";
import { ResendEmailDialogComponent } from "./features/reports/events-page/resend-email-dialog/resend-email-dialog.component";
import { CancelFuturePaymentDialogComponent } from "./features/reports/events-page/cancel-future-payment-dialog/cancel-future-payment-dialog.component";
import { VoidPaymentDialogComponent } from "./features/reports/events-page/void-payment-dialog/void-payment-dialog.component";
import { RequestListPageComponent } from "./features/requests/request-list-page/request-list-page.component";
import { UserListPageComponent } from "./features/users/user-list-page/user-list-page.component";
import { AuthGuard } from "./shared/auth/auth.guard";
import { AppSettings } from "./shared/models/app-settings";
import { BreadcrumbModule } from "./shared/widgets/breadcrumb/breadcrumb.module";
import { PaginatorModule } from "./shared/widgets/paginator/paginator.module";
import { SelectSearchModule } from "./shared/widgets/select-search/select-search.module";
import { JsonViewerComponentModule } from "./shared/widgets/json-viewer/json-viewer.module";
import { SnackBarModule } from "./shared/widgets/snack-bar/snack-bar.module";
import { MyAccountPageComponent } from "./features/users/my-account-page/my-account-page.component";
import { MyAccountUserLookupComponent } from "./features/users/my-account-user-lookup/my-account-user-lookup.component";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from "./shared/widgets/material.module";
import { MyAccountFusionAccountLookupComponent } from "./features/fusion/my-account-fusion-account-lookup/my-account-fusion-account-lookup.component";
import { MyAccountTransferOwnershipComponent } from "./features/users/my-account-transfer-ownership/my-account-transfer-ownership.component";
import { MyAccountTransferOwnershipSelectorComponent } from "./features/users/my-account-transfer-ownership-selector-page/my-account-transfer-ownership-selector-page.component";
import { MyAccountMassTransferOwnershipComponent } from './features/users/my-account-mass-transfer-ownership/my-account-mass-transfer-ownership.component';
import { ConfirmFusionProfileDeleteDialogComponent } from './features/fusion/my-account-fusion-account-lookup/confirm-fusion-profile-delete-dialog/confirm-fusion-profile-delete-dialog.component';
import { ConfirmTransferOwnershipDialogComponent } from './features/users/my-account-transfer-ownership/confirm-transfer-ownership-dialog/confirm-transfer-ownership-dialog.component';
import { ConfirmUnlinkAccountDialogComponent } from "./features/users/my-account-transfer-ownership/confirm-unlink-account-dialog/confirm-unlink-account-dialog.component";
import { MyAccountNotificationComponent } from './features/notification/my-account-notification/my-account-notification.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material";
import { MyAccountNotificationLookupComponent } from './features/notification/my-account-notification-lookup/my-account-notification-lookup.component';
import { RequestDetailsPageComponent } from "./features/requests/request-details-page/request-details-page.component";
import { BusinessUnitLookupComponent } from './features/business-unit/business-unit-lookup/business-unit-lookup.component';
import { BusinessUnitDetailComponent } from "./features/business-unit/business-unit-detail/business-unit-detail.component";
import { FacilityLookupComponent } from './features/facility/facility-lookup/facility-lookup.component';
import { FacilityDetailComponent } from './features/facility/facility-detail/facility-detail.component';
import { CdkAccordion, CdkAccordionItem, CdkAccordionModule } from "@angular/cdk/accordion";
import { EventsPageComponent } from "./features/reports/events-page/events-page.component";
import { RequestStatsPageComponent } from "./features/requests/request-stats-page/request-stats-page.component";
import { ReportsPageComponent } from './features/reports/reports-page/reports-page.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateGroupPageComponent } from "./features/groups/create-group-page/create-group-page.component";
import { EditGroupPageComponent } from "./features/groups/edit-group-page/edit-group-page.component";
import { GroupListPageComponent } from "./features/groups/group-list-page/group-list-page.component";
import { CreateUserPageComponent } from "./features/users/create-user-page/create-user-page.component";
import { MyAccountCreateManagedAutopayAccountComponent } from "./features/users/my-account-create-managed-autopay-account/my-account-create-managed-autopay-account.component";
import { MyAccountToolDescriptionPageComponent } from "./features/users/my-account-tool-description-page/my-account-tool-description-page.component";
import { MyAccountAccountLookupComponent } from "./features/users/my-account-account-lookup/my-account-account-lookup.component";
import { MyAccountDeleteAzureProfileComponent } from './features/users/my-account-delete-azure-profile/my-account-delete-azure-profile.component';
import { CreateBusinessUnitComponent } from './features/business-unit/create-business-unit/create-business-unit.component';
import { CreateFacilityComponent } from './features/facility/create-facility/create-facility.component';
import { CreatePermissionGroupPageComponent } from "./features/users/create-permission-group-page/create-permission-group-page.component";
import { EditPermissionGroupPageComponent } from "./features/users/edit-permission-group-page/edit-permission-group-page.component";
import { PermissionGroupListPageComponent } from "./features/users/permission-group-list-page/permission-group-list-page.component";
import { ErrorHandlingModule } from "./features/error-handling/error-handling.module";
import { ErrorHandlingComponent } from './features/error-handling/error-handling/error-handling.component';
import { MyAccountCustomerFinderComponent } from "./features/users/my-account-customer-finder/my-account-customer-finder.component";
import { CreateLinkComponent } from "./features/csr-link/create-link/create-link.component";
import { LinkDetailsComponent } from "./features/csr-link/link-details/link-details.component";
import { LinkListComponent } from "./features/csr-link/link-list/link-list.component";
import { PaymentLinkSelectorComponent } from "./features/csr-link/link-selector/link-selector.component";
import { PaymentTypeLinkSelectorComponent } from "./features/csr-link/payment-selector/payment-selector.component";
import { CreateLinkInvoiceComponent } from "./features/csr-link/create-link-invoice/create-link-invoice.component";
import { MyAccountAccountDetailsComponent } from "./features/users/my-account-account-details/my-account-account-details.component";
import { MyAccountPayMyBillDataValidationComponent } from "./features/users/my-account-paymybill-datavalidation/my-account-paymybill-datavalidation.component";
import { RecordSyncComponent } from "./features/record-sync/record-sync.component";
import { MyAccountDeleteEbillingComponent } from "./features/users/my-account-delete-ebilling/my-account-delete-ebilling.component";
import { CurrentUserErrorPageComponent } from "./features/error-handling/current-user-error-page/current-user-error-page.component";
import { MyAccountFindAccountsComponent } from "./features/users/my-account-find-accounts/my-account-find-accounts.component";
import { AreasDetailsPageComponent } from "./features/ecommerce/areas-details/areas-details.component";
import { AreasLookupPageComponent } from "./features/ecommerce/areas-lookup/areas-lookup.component";
import { BillingCyclesDetailsPageComponent } from "./features/ecommerce/billing-cycles-details/billing-cycles-details.component";
import { BillingCyclesLookupPageComponent } from "./features/ecommerce/billing-cycles-lookup/billing-cycles-lookup.component";
import { BlacklistLookupPageComponent } from "./features/ecommerce/blacklist-lookup/blacklist-lookup.component";
import { BlacklistDetailsPageComponent } from "./features/ecommerce/blacklist-details/blacklist-details.component";
import { FeeDetailsPageComponent } from "./features/ecommerce/fees-details/fees-details.component";
import { FeesLookupPageComponent } from "./features/ecommerce/fees-lookup/fees-lookup.component";
import { PromoCodesDetailsPageComponent } from "./features/ecommerce/promo-codes-details/promo-codes-details.component";
import { PromoCodesLookupPageComponent } from "./features/ecommerce/promo-codes-lookup/promo-codes-lookup.component";
import { ServiceCodesDetailsPageComponent } from "./features/ecommerce/service-codes-details/service-codes-details.component";
import { ServiceCodesLookupPageComponent } from "./features/ecommerce/service-codes-lookup/service-codes-lookup.component";
import { ServiceFrequencyDetailsPageComponent } from "./features/ecommerce/service-frequency-details/service-frequency-details.component";
import { ServiceFrequencyLookupPageComponent } from "./features/ecommerce/service-frequency-lookup/service-frequency-lookup.component";
import { ServiceTypesDetailsPageComponent } from "./features/ecommerce/service-types-details/service-types-details.component";
import { ServiceTypesLookupPageComponent } from "./features/ecommerce/service-types-lookup/service-types-lookup.component";
import { AreaCreatePageComponent } from "./features/ecommerce/area-create-page/area-create-page.component";
import { BillingCycleCreatePageComponent } from "./features/ecommerce/billing-cycle-create-page/billing-cycle-create-page.component";
import { FeeCreatePageComponent } from "./features/ecommerce/fee-create-page/fee-create-page.component";
import { ServiceTypeCreatePageComponent } from "./features/ecommerce/service-type-create-page/service-type-create-page.component";
import { ServiceFrequencyCreatePageComponent } from "./features/ecommerce/service-frequency-create-page/service-frequency-page.component";
import { ServiceCodeCreatePageComponent } from "./features/ecommerce/service-code-create-page/service-code-create-page.component";
import { PromoCodeCreatePageComponent } from "./features/ecommerce/promo-code-create-page/promo-code-create-page.component";
import { BlacklistCreatePageComponent } from "./features/ecommerce/blacklist-create-page/blacklist-create-page.component";
import { MapComponent } from "./features/map/map.component";
import { BasemapComponent } from "./features/map/basemap/basemap.component";
import { Editor2Component } from "./features/map/geofence/editor2/editor2.component";

export function MSALConfigFactory(
  locale: string,
  settings: AppSettings
): Configuration {
  const redirectUri = settings.auth.redirectUri;
  const postLogoutRedirectUri = settings.auth.postLogoutRedirectUri;
  return {
    auth: {
      clientId: settings.auth.clientId,
      authority: settings.auth.loginAuthority,
      validateAuthority: true,
      redirectUri: `${redirectUri || window.location.origin}`,
      postLogoutRedirectUri: `${postLogoutRedirectUri || window.location.origin
        }`,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };
}

export function MSALAngularConfigFactory(
  settings: AppSettings
): MsalAngularConfiguration {
  const protectedResourceMap: [string, string[]][] = [
    ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
    ["https://graph.microsoft.com/v1.0/me/Photos/48X48/$value", ["user.read"]],
    [settings.api.baseUri, [settings.auth.clientId]],
  ];

  return {
    popUp: false,
    consentScopes: settings.auth.consentScopes,
    unprotectedResources: ["appsettings.json"],
    protectedResourceMap,
    extraQueryParameters: {},
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    RequestListPageComponent,
    RequestDetailsPageComponent,
    RequestStatsPageComponent,
    MapComponent,
    BasemapComponent,
    Editor2Component,
    ReportsPageComponent,
    EventsPageComponent,
    CloseRequestNotesDialogComponent,
    AssigneeDialogComponent,
    ResendEmailDialogComponent,
    CancelFuturePaymentDialogComponent,
    VoidPaymentDialogComponent,
    SecurePipe,
    CreateUserPageComponent,
    AreaCreatePageComponent,
    BillingCycleCreatePageComponent,
    UserListPageComponent,
    UserEditPageComponent,
    MyAccountPageComponent,
    MyAccountUserLookupComponent,
    MyAccountAccountDetailsComponent,
    MyAccountAccountLookupComponent,
    MyAccountCustomerFinderComponent,
    CreateLinkComponent,
    LinkDetailsComponent,
    LinkListComponent,
    PaymentLinkSelectorComponent,
    PaymentTypeLinkSelectorComponent,
    CreateLinkInvoiceComponent,
    MyAccountDeleteAzureProfileComponent,
    MyAccountDeleteEbillingComponent,
    MyAccountTransferOwnershipComponent,
    MyAccountTransferOwnershipSelectorComponent,
    MyAccountMassTransferOwnershipComponent,
    MyAccountFusionAccountLookupComponent,
    MyAccountPayMyBillDataValidationComponent,
    ConfirmFusionProfileDeleteDialogComponent,
    ConfirmTransferOwnershipDialogComponent,
    ConfirmUnlinkAccountDialogComponent,
    RecordSyncComponent,
    MyAccountCreateManagedAutopayAccountComponent,
    MyAccountToolDescriptionPageComponent,
    MyAccountNotificationComponent,
    MyAccountFindAccountsComponent,
    MyAccountNotificationLookupComponent,
    BusinessUnitLookupComponent,
    BusinessUnitDetailComponent,
    FacilityLookupComponent,
    FacilityDetailComponent,
    CreateGroupPageComponent,
    CreatePermissionGroupPageComponent,
    EditPermissionGroupPageComponent,
    PermissionGroupListPageComponent,
    EditGroupPageComponent,
    GroupListPageComponent,
    MyAccountNotificationLookupComponent,
    MyAccountDeleteAzureProfileComponent,
    CreateBusinessUnitComponent,
    CreateFacilityComponent,
    CurrentUserErrorPageComponent,
    FeeCreatePageComponent,
    BlacklistCreatePageComponent,
    ServiceTypeCreatePageComponent,
    ServiceCodeCreatePageComponent,
    ServiceFrequencyCreatePageComponent,
    PromoCodeCreatePageComponent,
    AreasDetailsPageComponent,
    AreasLookupPageComponent,
    BillingCyclesDetailsPageComponent,
    BillingCyclesLookupPageComponent,
    BlacklistLookupPageComponent,
    BlacklistDetailsPageComponent,
    FeeDetailsPageComponent,
    FeesLookupPageComponent,
    PromoCodesDetailsPageComponent,
    PromoCodesLookupPageComponent,
    ServiceCodesDetailsPageComponent,
    ServiceCodesLookupPageComponent,
    ServiceFrequencyDetailsPageComponent,
    ServiceFrequencyLookupPageComponent,
    ServiceTypesDetailsPageComponent,
    ServiceTypesLookupPageComponent,
  ],
  entryComponents: [
    CloseRequestNotesDialogComponent,
    ConfirmFusionProfileDeleteDialogComponent,
    ConfirmTransferOwnershipDialogComponent,
    ConfirmUnlinkAccountDialogComponent,
    AssigneeDialogComponent,
    ResendEmailDialogComponent,
    CancelFuturePaymentDialogComponent,
    VoidPaymentDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    MsalModule,
    PaginatorModule,
    SnackBarModule,
    SelectSearchModule,
    JsonViewerComponentModule,
    BreadcrumbModule,
    NgbModule,
    NgSelectModule,
    NgbPaginationModule,
    CdkAccordionModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ErrorHandlingModule
  ],
  providers: [
    BusinessUnitClient,
    FacilityClient,
    HolidayClient,
    RequestsClient,
    UsersClient,
    GroupsClient,
    LookupClient,
    MyAccountUsersClient,
    MyAccountAccountsClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MyAccountFusionClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MyAccountNotificationClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
      deps: [LOCALE_ID, AppSettings],
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
      deps: [AppSettings],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-CA'
    },
    MsalService,
    MatNativeDateModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private msalService: MsalService,
    private broadcastService: BroadcastService
  ) {
    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        //console.error(authError);
      }
    });
    this.broadcastService.subscribe("msal:loginFailure", (payload) => {
      console.error("msal:loginFailure");
      console.error(payload);
      this.msalService.loginRedirect();
    });
    this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
      console.error("msal:acquireTokenFailure");
      console.error(payload);
      this.msalService.loginRedirect();
    });
    this.broadcastService.subscribe("msal:ssoFailure", (payload) => {
      console.error("msal:ssoFailure");
      console.error(payload);
    });
    this.broadcastService.subscribe("msal:loginSuccess", (payload) => { });
    this.broadcastService.subscribe(
      "msal:acquireTokenSuccess",
      (payload) => { }
    );
    this.broadcastService.subscribe("msal:ssoSuccess", (payload) => { });
  }
}
