import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../material.module";
import { JsonViewerComponent } from "./json-viewer.component";

@NgModule({
  declarations: [JsonViewerComponent],
  imports: [CommonModule, MaterialModule],
  exports: [JsonViewerComponent],
  entryComponents: [JsonViewerComponent],
})
export class JsonViewerComponentModule {}
