import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { MyAccountNotificationClient, MyAccountUsersClient, NotificationFilterBy, NotificationFilterByFilterBy, NotificationListViewModel, NotificationSortBy, NotificationSortBySortBy, NotificationsQuery } from 'src/app/apis/customer-care-api.generated';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import { AuthService } from "src/app/shared/auth/auth.service";

@Component({
  selector: 'app-my-account-notification-lookup',
  templateUrl: './my-account-notification-lookup.component.html',
  styleUrls: ['./my-account-notification-lookup.component.css']
})
export class MyAccountNotificationLookupComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private isInitialized: boolean;
  
  notificationFound: boolean = false;
  isLoading: boolean = false;
  pageIndex: number = 1;
  pageSize: number = 10;
  totalRequests: number;
  filterBy: NotificationFilterByFilterBy[] = [];
  sortBy: NotificationSortBySortBy[] = [];
  isAllowedToCreateNotification = false;
  createActive: boolean = false;

  notificationList: NotificationListViewModel[] = null;


  toolDescription = "This tool is meant to display the notifications created. \n We can delete a notification from the list by selecting the Delete Icon. \n We can also create a notification by selecting the recipient(s) and then entering the subject, body and date of the notification.\n Once a notification is created, it will be sent to the recipient(s) and appear in the table."; 

  constructor(private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private notificationClient: MyAccountNotificationClient,
    private authService: AuthService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.sortBy = [];
    /*-----------DEFAULT SORT BY ------------*/
    const sortByDate = new NotificationSortBySortBy({
      property: NotificationSortBy.NotificationDate,
      descendingOrder: true,
    });

    this.sortBy.push(sortByDate);
    /*-----------DEFAULT SORT BY ------------*/

    this.RetreiveNotifications();

    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(user => {
            if(user) {
              this.isAllowedToCreateNotification = user.isAllowedToCreateNotification;
            }
          })
      );
  }

  private RetreiveNotifications() {
    var command = new NotificationsQuery();
    command.page = this.pageIndex;
    command.pageSize = this.pageSize;
    command.sortBy = this.sortBy;
    command.filterBy = this.filterBy.length == 0 ? undefined : this.filterBy;

    this.isLoading = true;
    this.subscription
      .add(
        this.notificationClient
          .getNotifications(command)
          .subscribe(
            result => {
              this.notificationList = result.records;
              this.totalRequests = result.totals[0].value;
              this.notificationFound = true;
              setTimeout(() => {
                this.isInitialized = true;
              }, 2000);
            },
            (error) => {
              console.error(error);
              this.snackBarService.ShowError(error);
            },
            () => {
              this.isLoading = false;
            }
          )
      );
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }

      this.pageSize = e.pageSize;
    }
  }

  delete = (notification: NotificationListViewModel) => {
    this.isLoading = true;

    this.subscription
      .add(
        this.notificationClient
          .deleteNotification(notification.notificationId)
          .subscribe(
            x => {
              this.snackBarService.ShowSuccess("Notification deleted.");
              this.notificationFound = false;

              this.RetreiveNotifications();
            },
            (error) => {
              this.snackBarService.ShowError(error);
            },
            () => {
              this.isLoading = false;
            }
          )
      );
  }

  onCreateClicked()
  {
    this.createActive = true;
  }

}
