import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErpApiClient, ErpEntityType, ErpSourceSystem, SyncRecordCommand } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';

@Component({
  selector: 'app-record-sync',
  templateUrl: './record-sync.component.html',
  styleUrls: ['./record-sync.component.css']
})
export class RecordSyncComponent {

  subscription: Subscription = new Subscription();

  constructor(
    private erpApiClient: ErpApiClient,
    private snackBarService: SnackBarService) { }

  sourceSystems = [
    { text: 'Trux', value: ErpSourceSystem.Trux },
    { text: 'Tower', value: ErpSourceSystem.Tower },
    { text: 'GFLOne', value: ErpSourceSystem.GFLOne },
  ];

  entityTypes = [
    { text: 'Customer', value: ErpEntityType.Customer },
    { text: 'Site', value: ErpEntityType.Site },
    { text: 'Service', value: ErpEntityType.Service },
    { text: 'Invoice', value: ErpEntityType.Invoice },
    { text: 'Work Order', value: ErpEntityType.WorkOrder },
    { text: 'Disposal Ticket', value: ErpEntityType.DisposalTicket },
    { text: 'Payment', value: ErpEntityType.Payment },
  ];

  toolDescription = "This tool is meant to update the data related to an account.\n This tool will sync the records related to the entity number entered."

  syncing: boolean = false;
  response: string = '';
  isError = false;

  syncFormGroup = new FormGroup({
    selectedSourceSystem: new FormControl(ErpSourceSystem.Trux),
    selectedEntityType: new FormControl(ErpEntityType.Customer),
    entityNumber: new FormControl('')
  });

  canSync() {
    return this.syncFormGroup.get('entityNumber').value.length && !this.syncing;
  }

  sync() {
    this.response = '';
    this.syncing = true;
    const data = new SyncRecordCommand({
      sourceSystem: this.syncFormGroup.value.selectedSourceSystem,
      entityType: this.syncFormGroup.value.selectedEntityType,
      entityNumber: this.syncFormGroup.value.entityNumber,
    });
    this.erpApiClient.syncRecord(data).subscribe(
      (response) => {
        this.response = response.result;
        // make this response a json
        try {
          this.response =JSON.parse(this.response);
        } catch (e) {
          // do nothing
        }
        if(response.isSuccess)
        {
          this.isError = false;
          this.snackBarService.ShowSuccess('Record Synced Successfully');
        }
        else{
          this.isError = true;
        }
        this.syncing = false;
      },
      (error) => {
        this.response = error;
        this.syncing = false;
        this.isError = true;
      }
    );
  }
}
